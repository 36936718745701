<template>
	<div>
        <DateRangeV2
            :start.sync="start_date"
            :end.sync="end_date"
            :dateSelect="true"
            :periodToShow="['personalise','jour','mois','mois_dernier']"
			@submit="loadDoses"
        />

        <CustomTable
            id_table="semence_doses"
            ref="table"
            :items="doses"
            :busy.sync="table_busy"
            primaryKey="semencedose_id"
            :hide_if_empty="true"
            :hrefsRoutes="config_table_hrefs"
        />
	</div>
</template>

<script type="text/javascript">
	import Congelation from "@/mixins/Congelation.js"
	import Navigation from "@/mixins/Navigation.js"
	import ShutterCongelation from "@/mixins/shutters-manager/Congelation.js"

	export default {
		name: 'StallionDoseListe',
		mixins: [Congelation, Navigation, ShutterCongelation],
		props: ['horse_id'],
		data () {
			return {
				start_date: new Date(new Date().setDate(new Date().getDate() - 31)),
				end_date: new Date(),
				doses: [],
				table_busy: false,
				config_table_hrefs: {
					'horse.horse_nom': {
						routeUniqueName: 'StallionFiche',
						routeName: 'horseFiche',
						params: {
							horse_id: 'horse.horse_id'
						}
					},
				}
			}
		},

		methods: {
			async loadDoses() {
				this.table_busy = true
				if(this.horse_id) 
					this.doses = await this.getDosesByStallion(this.horse_id, this.start_date, this.end_date)
				else 
					this.doses = await this.getDoses(this.start_date, this.end_date)
				this.table_busy = false
			}
		},

		components: {
			CustomTable : () => import('GroomyRoot/components/Table/CustomTable'),
			DateRangeV2: () => import('GroomyRoot/components/Inputs/DateRangeV2')
		}
	}

</script>